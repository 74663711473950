<template>
	<div>
		<div class="p-3 h-full flex flex-column" style="border-radius: 6px;height: auto;"
			align="center">
			<div align="center">
				<div class='grid' style="display: flex;flex-direction: row;">
					<div class="col-12 md:col-3">
						<Image v-if='language' :title='author.content_en' :src="author.avatar" width="100" preview alt="" />
						<Image v-if='!language' :title='author.content_zh' :src="author.avatar" width="100" preview alt="" />
					</div>
					<div class="col-12 md:col-9">
						<h4  v-if='language' style="color: var(--primary-color);margin-top:0.625rem;font-weight: 800;">
							I am engineer Chaoxu Wei</h4>
						<h4 v-if='!language' style="color: var(--primary-color);margin-top:0.625rem;font-weight: 800;">
							我是工程师韦朝旭</h4>
						<Button v-if='!language' style="margin-top: 5px;padding: 10px;"
							label="欢迎联系我!" class="p-button-plain" />
						<Button v-if='language' style="margin-top: 5px;padding: 10px;"
							label="Welcome to Contact me!" class="p-button-plain" />
					</div>
				</div>

				<!-- 				<p :title='author.content' style="font-weight: 600;font-size: 14px;">
					{{author.title}}<br>{{author.work}}
				</p> -->
				<!-- 				<p :title='author.content' style="font-weight: 600;font-size: 14px;">
					{{author.work}}
				</p> -->
				<div title="click and contact me for code or any question">
					<!-- 					<a target="_blank" :href="author.facebook"><Button icon="pi pi-facebook"
							class="p-button-rounded mr-2 mb-2" /></a>
					<a target="_blank" :href="author.twitter"><Button icon="pi pi-twitter"
							class="p-button-rounded p-button-info mr-2 mb-2" /></a> -->
					<Button icon="pi pi-whatsapp" @click="toggleWhatsApp" style="width: 100%;font-weight: 600;"
						class="p-button-success p-button-rounded mr-2 mb-2" label='WhatsApp: +86 18385537403'></Button>
					<OverlayPanel ref="whats_app" appendTo="body" :showCloseIcon="true">
						<img src="images/my_whatsapp.jpg" style="width: 300px;" />
					</OverlayPanel>
					<Button icon="fa fa-weixin" label='电话/微信：18385537403' @click="toggle" style="width: 100%;font-weight: 600;"
						class="p-button-success p-button-rounded mr-2 mb-2"></Button>
					<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
						<img src="images/my_wexin.jpg" style="width: 300px;" />
					</OverlayPanel>
					<Button icon="pi pi-envelope" @click='show_email=!show_email'
						class="p-button p-button-outlined p-button-warning mr-2 mb-2"
						style="width: 100%;font-weight: 600;background-color:rgba(255, 255, 150, 0.15)"
						label="Email: weichaoxu1998@gmail.com"></Button>
				</div>
				<div v-if='show_email' class="p-inputgroup">
					<InputText title='my email address' type="text" :value='author.gmail' placeholder="Price" />
					<span title="close" class="p-inputgroup-addon" @click='show_email=false'><i class="pi pi-times"
							aria-hidden="true"></i></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				show_email: false,
				author: {
					'avatar': 'images/avatar/weichaoxu.jpg',
					'name': 'Chaoxu Wei',
					'title': 'Student at Tongji University',
					'work': 'Developer && Blogger',
					'content_zh': '我具有专业广泛的PDF处理前沿知识，欢迎联系',
					'content_en': 'I have extensive and cutting-edge knowledge in PDF processing. Welcome to contact me',
					'twitter': 'https://twitter.com/Joker75342012?t=lvUwjAYZEp_3vRSNOmL0XA&s=09',
					'facebook': 'https://www.facebook.com/profile.php?id=100083516062282',
					'gmail': 'weichaoxu1998@gmail.com',
				}
			}
		},
		networkService: null,
		created() {
			//实例化NetWorkService对象
		},
		mounted() {

		},
		methods: {
			toggle(event) {
				this.$refs.op.toggle(event);
			},
			toggleWhatsApp(event) {
				this.$refs.whats_app.toggle(event);
			}
		},
		computed: {
			language() {
				if (this.$appState.language == 'en') {
					return true;
				} else {
					return false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
</style>
